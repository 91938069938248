<template>
  <main>
    <div class="form" v-if="!formWasSubmitted" :class="{ inactive: isSendingForm }">
      <span class="big">Сброс пароля</span>
      <span>Введите e-mail, на который зарегистрирован канал — на него будет отправлена ссылка для смены пароля.</span>
      <input type="email" placeholder="E-mail" autocomplete="username" v-model="email" @keyup.enter="requestPasswordReset" />
      <div
        class="button request-password-reset-button"
        @click="requestPasswordReset"
        :class="{ enabled: isEmailEntered }"
      >Выслать письмо</div>
      <span>Если Вы не помните адрес e-mail или у Вас нет к нему доступа — обратитесь в поддержку по адресу
        <a href="mailto:support@helperia.ru?subject=Не%20получается%20восстановить%20пароль">support@helperia.ru</a>.
      </span>
      <span>Если письмо долго не приходит, проверьте папку "Спам" или попробуйте запросить выслать письмо ещё раз перед тем, как обратиться в поддержку.</span>
    </div>
    <div class="form" v-if="formWasSubmitted">
      <span>Если почтовый адрес был указан верно, в течение нескольких минут на него придёт письмо со ссылкой для сброса пароля.</span>
    </div>
  </main>
</template>

<script>
export default {
  name: 'ResetPassword',
  data() {
    return {
      email: '',
      isSendingForm: false,
      formWasSubmitted: false,
    }
  },

  computed: {
    isEmailEntered() {
      return this.email.length > 0
    }
  },

  methods: {
    requestPasswordReset() {
      if(this.isSendingForm) {
        return
      }

      if(this.email.length < 1) {
        return
      }

      this.isSendingForm = true
      this.$root.makeAPIRequest('auth', 'reset_password', {
        email: this.email.trim()
      })
        .then(response => {
          if(response.data.error) {
            console.error(response.data.message)
            alert(response.data.message)
            this.isSendingForm = false
            return
          }

          this.formWasSubmitted = true
          this.isSendingForm = false
        })
    }
  }
}
</script>

<style lang="less" scoped>
  @import (reference) '../shared.less';

  main {
    width: var(--content-width);
    align-self: center;
    display: flex;
  }

  .form {
    .small-form();
  }

  .big {
    .big-header();
  }

  span {
    margin-top: 0.5em;
  }

  input {
    .input();
    margin-top: 1em;
  }

  .request-password-reset-button {
    background-color: @color-accent;
    color: @color-block-background;
    padding: 0.5em;
    text-align: center;
    font-size: @font-size-normal;
    margin-top: 0.5em;
    cursor: pointer;
    opacity: 0.5;
    pointer-events: none;

    &.enabled {
      pointer-events: initial;
      opacity: initial;
    }
  }

  .form.inactive {
    pointer-events: none;
    opacity: 0.5;
  }
</style>
